import React from 'react'
import { css } from '@emotion/core'
import Fade from 'react-reveal/Fade'

const Line = ({ children, className, animated = false }) => {
  const Component = () => (
    <div
      css={css`
        padding-bottom: 48px;
        max-width: 768px;
      `}
      className={className}
    >
      {children}
    </div>
  )
  return animated ? (
    <Fade bottom distance="24px" duration={750}>
      <Component />
    </Fade>
  ) : (
    <Component />
  )
}

export default Line
