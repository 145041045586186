import React from 'react'
import { css, Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { Helmet } from 'react-helmet'

import { injectIntl } from 'gatsby-plugin-intl'
import theme from '../utils/theme'

import Header from './Header'
import Footer from './Footer'

import breakpoints from '../utils/breakpoints'

const Layout = ({ intl, children, lightFooter }) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        * {
          border: 0;
          margin: 0;
          padding: 0;
          font-family: Montserrat, sans-serif;
          font-weight: 300;
          box-sizing: border-box;
        }
      `}
    />
    <Helmet>
      <html lang={intl.locale} />
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:300,600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <div
      css={theme => css`
        min-height: 100vh;
        background: ${theme.colors.white};
        position: relative;
      `}
    >
      <Header />
      <main
        css={theme =>
          css`
            padding-top: ${theme.constants.headerHeightDesktop}px;

            ${breakpoints.mobile} {
              padding-top: ${theme.constants.headerHeightMobile}px;
            }
          `
        }
      >
        {children}
      </main>
      <Footer lightFooter={lightFooter} />
    </div>
  </ThemeProvider>
)

export default injectIntl(Layout)
