import React from 'react'
import { css } from '@emotion/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const IconLink = ({ to, children, className, label, ...rest }) => (
  <OutboundLink
    aria-label={label}
    href={to}
    target="_blank"
    className={className}
    rel="noreferrer noopener"
    css={theme => css`
      color: ${theme.colors.white};
      text-decoration: none;
      padding: 8px;
      display: flex;
      align-content: center;
      justify-content: center;

      :hover {
        opacity: 0.65;
      }

      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
    {...rest}
  >
    {children}
  </OutboundLink>
)

export default IconLink
