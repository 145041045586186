import React from 'react'
import { css } from '@emotion/core'
import breakpoints from '../../utils/breakpoints'

const PartnerImage = ({ src, partner, graySrc }) => (
  <div
    css={css`
      height: 100vw;
      width: 100vw;
      max-width: 136px;
      max-height: 136px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1);

      :hover {
        transform: scale(1.05);

        img:last-of-type {
          opacity: 0;
        }

        img:first-of-type {
          opacity: 1;
        }
      }

      ${breakpoints.xs} {
        max-width: 150px;
        max-height: 150px;
      }

      ${breakpoints.sm} {
        max-width: 175px;
        max-height: 175px;
      }

      ${breakpoints.lg} {
        max-width: 200px;
        max-height: 200px;
      }

      transition: filter 0.2s ease-out, transform 0.2s ease-in-out,
        opacity 0.2s ease-out;
    `}
  >
    <img
      css={css`
        width: 80%;
        object-fit: contain;
        margin: 0 auto;
        position: absolute;
        opacity: 0;
      `}
      src={src}
      alt={partner}
    />
    {graySrc && (
      <img
        css={css`
          width: 80%;
          object-fit: contain;
          margin: 0 auto;
          position: absolute;
          opacity: 1;
        `}
        src={graySrc}
        alt={`${partner}_gray`}
      />
    )}
  </div>
)

export default PartnerImage
