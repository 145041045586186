import React from 'react'
import { css } from '@emotion/core'
import { Link as IntlLink } from 'gatsby-plugin-intl'

const Link = ({ to, className, children, ...rest }) => (
  <IntlLink
    to={to}
    className={className}
    {...rest}
    css={css`
      text-decoration: none;
    `}
  >
    {children}
  </IntlLink>
)

export default Link
