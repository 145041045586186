import React from 'react'
import { css, keyframes } from '@emotion/core'

const move = keyframes`
  0% {
    transform: translate(0%, 0%);
  }
  10% {
    transform: translate(1%, 0%);
  }
  20% {
    transform: translate(1%, 1%);
  }
  30% {
    transform: translate(0%, 1%);
  }
  40% {
    transform: translate(-1%, 1%);
  }
  50% {
    transform: translate(-1%, 0%);
  }
  60% {
    transform: translate(-1%, -1%);
  }
  70% {
    transform: translate(-1%, -2%);
  }
  80% {
    transform: translate(0%, -2%);
  }
  90% {
    transform: translate(0%, -1%);
  }
  100% {
    transform: translate(0%, 0%);
  }
`

const Rocket = ({ className, fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    version="1.1"
    background="transparent"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className={className}
    css={theme => css`
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
      fill: ${fill || theme.colors.gray};

      :hover {
        #flame {
          fill: url(#flameColor);
          animation: ${move} 0.6s linear infinite;
          animation-delay: 0.2s;
        }

        #body {
          animation: ${move} 0.6s linear infinite;
        }
      }

      #flame {
        fill: ${fill || theme.colors.gray};
      }
    `}
  >
    <defs>
      <linearGradient id="flameColor" x1="0%" y1="100%" x2="100%" y2="0%">
        <stop offset="0%" style={{ stopColor: '#ec9f05' }} />
        <stop offset="100%" style={{ stopColor: '#ff4e00' }} />
      </linearGradient>
    </defs>
    <path
      id="body"
      d="M461.8,53.6c-0.4,-1.7 -1.6,-3 -3.3,-3.4c-54.4,-13.3 -180.1,34.1 -248.2,102.2c-13.3,13.3 -24.2,26.4 -33.1,39.1c-21,-1.9 -42,-0.3 -59.9,7.5c-50.5,22.2 -65.2,80.2 -69.3,105.1c-1,5.9 3.9,11 9.8,10.4l81.1,-8.9c0.1,7.8 0.6,14 1.1,18.3c0.4,4.2 2.3,8.1 5.3,11.1l31.4,31.4c3,3 6.9,4.9 11.1,5.3c4.3,0.5 10.5,1 18.2,1.1l-8.9,81c-0.6,5.9 4.5,10.8 10.4,9.8c24.9,-4 83,-18.7 105.1,-69.2c7.8,-17.9 9.4,-38.8 7.6,-59.7c12.7,-8.9 25.9,-19.8 39.2,-33.1c68.4,-68 115.5,-190.9 102.4,-248Zm-163.2,159.9c-16.7,-16.7 -16.7,-43.7 0,-60.4c16.7,-16.7 43.7,-16.7 60.4,0c16.7,16.7 16.7,43.7 0,60.4c-16.7,16.7 -43.7,16.7 -60.4,0Z"
    />
    <path
      id="flame"
      d="M174.5,380.5c-4.2,4.2 -11.7,6.6 -19.8,8c-18.2,3.1 -34.1,-12.8 -31,-31c1.4,-8.1 3.7,-15.6 7.9,-19.7l0.1,-0.1c2.3,-2.3 0.4,-6.1 -2.8,-5.7c-9.8,1.2 -19.4,5.6 -26.9,13.1c-18,18 -19.7,84.8 -19.7,84.8c0,0 66.9,-1.7 84.9,-19.7c7.6,-7.6 11.9,-17.1 13.1,-26.9c0.3,-3.2 -3.6,-5.1 -5.8,-2.8Z"
    />
  </svg>
)

export default Rocket
