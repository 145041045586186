import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import breakpoints from '../../utils/breakpoints'

const LanguageButton = styled('button')`
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  font-size: ${({ theme }) => theme.fontSize.xSmall};

  :disabled {
    cursor: default;
    opacity: 0.65;
  }

  :hover {
    opacity: 0.65;
  }

  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`

const LanguageSelector = () => {
  return (
    <IntlContextConsumer>
      {({ language: currentLangauge }) => (
        <div
          css={theme => css`
            line-height: 18px;
            color: ${theme.colors.white};
            padding: 0 48px 0 64px;
            user-select: none;

            ${breakpoints.mobile} {
              padding: 16px;
            }
          `}
        >
          <LanguageButton
            aria-label="in english"
            disabled={currentLangauge === 'en'}
            onClick={() => {
              changeLocale('en')
            }}
          >
            en
          </LanguageButton>
          &nbsp;|&nbsp;
          <LanguageButton
            aria-label="magyarul"
            disabled={currentLangauge === 'hu'}
            onClick={() => {
              changeLocale('hu')
            }}
          >
            hu
          </LanguageButton>
        </div>
      )}
    </IntlContextConsumer>
  )
}

export default LanguageSelector
