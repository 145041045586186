import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

const LineWrapper = styled('span')`
  position: absolute;
  right: 0;
  left: 0;
`

const Line = styled('span')`
  height: 1px;
  position: absolute;
  right: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
`

const MenuButton = ({ open, ...rest }) => (
  <button
    css={css`
      height: 24px;
      width: 24px;
      position: relative;
      background: transparent;
      cursor: pointer;

      :focus {
        outline: 0;
      }

      :hover {
        opacity: 0.65;
      }

      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
    {...rest}
  >
    <LineWrapper
      css={css`
        transform: ${open ? 'rotate(45deg)' : 'none'};
        transition: ${open
          ? 'transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s'
          : 'transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)'};
      `}
    >
      <Line
        css={css`
          transform: ${open ? 'none' : 'translateY(-8px);'};
          transition: ${open
            ? 'transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)'
            : 'transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s'};
        `}
      />
    </LineWrapper>
    <LineWrapper
      css={css`
        opacity: ${open ? '0' : '1'};
        transition: opacity 0.1s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s;
      `}
    >
      <Line />
    </LineWrapper>
    <LineWrapper
      css={css`
        transform: ${open ? 'rotate(-45deg)' : 'none'};
        transition: ${open
          ? 'transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s'
          : 'transform .1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)'};
      `}
    >
      <Line
        css={css`
          transform: ${open ? 'none' : 'translateY(8px);'};
          transition: ${open
            ? 'transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96)'
            : 'transform .1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) .1008s'};
        `}
      />
    </LineWrapper>
  </button>
)

export default MenuButton
