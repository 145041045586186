import React from 'react'
import { css } from '@emotion/core'

const Logo = ({ fill, className }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 512 512"
    version="1.1"
    background="transparent"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className={className}
    css={theme => css`
      fill: none;
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;

      fill: ${fill || theme.colors.white};
    `}
  >
    <path d="M220.868,512l291.136,0l0,-42.391l-124.371,-124.372c-55.588,55.587 -111.177,111.175 -166.765,166.763Z" />
    <path d="M0,311.658l0,200.342l95.046,0l177.023,-177.022l-147.687,-147.693c-41.461,41.458 -82.921,82.915 -124.382,124.373Z" />
    <path d="M0,291.14l0,-291.137l291.146,0l-291.146,291.137Z" />
    <path d="M416.958,0.003l-177.023,177.023l272.069,272.065l0,-449.088l-95.046,0Z" />
  </svg>
)

export default Logo
