import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { withTheme } from 'emotion-theming'
import {
  IoLogoFacebook,
  IoLogoGithub,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoLogoTwitter,
} from 'react-icons/io'

import { LogoSingle, IconLink, EmailLink, Link, PhoneLink } from './common'
import breakpoints from '../utils/breakpoints'

const LeftTriangle = styled('div')`
  width: 0;
  height: 0;
  border-bottom: ${({ theme }) => theme.constants.footerHeight}px solid
    ${({ theme }) => theme.colors.gray};
  border-right: 190vw solid transparent;

  ${breakpoints.sm} {
    border-right: 150vw solid transparent;
  }

  ${breakpoints.md} {
    border-right: 120vw solid transparent;
  }

  ${breakpoints.lg} {
    border-right: 90vw solid transparent;
  }

  transition: border-right 0.2s ease-out;

  position: absolute;
  left: 0;
  bottom: 0;
`

const RightTriangle = styled('div')`
  width: 0;
  height: 0;
  border-bottom: ${({ theme }) => theme.constants.footerHeight * 0.72}px solid
    ${({ theme }) => theme.colors.dark};
  border-left: 85vw solid transparent;

  ${breakpoints.sm} {
    border-left: 70vw solid transparent;
  }

  ${breakpoints.md} {
    border-left: 60vw solid transparent;
  }

  ${breakpoints.lg} {
    border-left: 55vw solid transparent;
  }

  transition: border-left 0.2s ease-out;

  position: absolute;
  right: 0;
  bottom: 0;
`

const LeftContent = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 24px 24px;
  color: ${({ theme }) => theme.colors.white};

  z-index: 1;
`

const RightContent = styled('div')`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0px 16px 16px 0px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSize.large};

  display: flex;
  flex-flow: wrap-reverse;
  justify-content: flex-end;

  max-width: 30%;
  min-width: 100px;

  z-index: 1;
`

const Footer = ({ theme, lightFooter }) => (
  <footer
    css={css`
      position: relative;
      height: ${theme.constants.footerHeight}px;
      overflow: hidden;
      background: ${lightFooter ? theme.colors.fullWhite : 'initial'};
    `}
  >
    <LeftTriangle />
    <LeftContent>
      <Link
        to="/"
        css={theme => css`
          color: ${theme.colors.white};
        `}
      >
        <LogoSingle
          fill={theme.colors.white}
          css={css`
            height: auto;
            width: 60px;
          `}
        />
        <div
          css={theme => css`
            font-size: ${theme.fontSize.xLarge};
          `}
        >
          Silivey
        </div>
      </Link>
      <div
        css={css`
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          text-align: left;
        `}
      >
        <EmailLink
          css={css`
            margin-bottom: 8px;
          `}
          data="hello@silivey.com"
        />
        <PhoneLink data="+36 (30) 903 2900" />
      </div>
    </LeftContent>

    <RightTriangle dark />
    <RightContent>
      <IconLink label="github" to="https://github.com/silivey">
        <IoLogoGithub />
      </IconLink>
      <IconLink label="linkedin" to="https://www.linkedin.com/company/silivey/">
        <IoLogoLinkedin />
      </IconLink>
      <IconLink label="instagram" to="https://www.instagram.com/silivey_/">
        <IoLogoInstagram />
      </IconLink>
      <IconLink label="facebook" to="https://www.facebook.com/SiliveyCo/">
        <IoLogoFacebook />
      </IconLink>
      <IconLink label="twitter" to="https://twitter.com/silivey/">
        <IoLogoTwitter />
      </IconLink>
    </RightContent>
  </footer>
)

export default withTheme(Footer)
