export default {
  colors: {
    white: '#F7F7F7',
    yellow: '#FDCF41',
    gray: '#4D4D4D',
    dark: '#2C2C2B',
    black: '#000',
    accent: '#7890E2',
    fullWhite: 'white',
    lightGray: '#A3A3A3',
  },
  constants: {
    headerHeightDesktop: 64,
    headerHeightMobile: 56,
    footerHeight: 240,
  },
  margin: {
    xxSmall: `${4}px`,
    xSmall: `${8}px`,
    small: `${16}px`,
    medium: `${24}px`,
    large: `${32}px`,
    xLarge: `${48}px`,
    xxLarge: `${72}px`,
  },
  fontSize: {
    xxSmall: `${12}px`,
    xSmall: `${14}px`,
    small: `${16}px`,
    medium: `${18}px`,
    large: `${24}px`,
    xLarge: `${32}px`,
    xxLarge: `${48}px`,
  },
}
