export { default as Link } from './Link'
export { default as Name } from './Name'
export { default as AnimatedName } from './AnimatedName'
export { default as Logo } from './Logo'
export { default as LogoSingle } from './LogoSingle'
export { default as Triangle } from './Triangle'
export { default as Rectangle } from './Rectangle'
export { default as IconLink } from './IconLink'
export { default as LanguageSelector } from './LanguageSelector'
export { default as PartnerImage } from './PartnerImage'
export { default as Service } from './Service'
export { default as Rocket } from './Rocket'
export { default as Student } from './Student'
export { default as Bed } from './Bed'
export { default as GraphQL } from './GraphQL'
export { default as CountUp } from './CountUp'
export { default as Stat } from './Stat'
export { default as ServiceList } from './ServiceList'
export { default as ServiceListItem } from './ServiceListItem'
export { default as EmailLink } from './EmailLink'
export { default as PhoneLink } from './PhoneLink'
export { default as Line } from './Line'
export { default as Motto } from './Motto'
export { default as SectionTitle } from './SectionTitle'
export { default as Strong } from './Strong'
