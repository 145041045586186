import React from 'react'
import { css, keyframes } from '@emotion/core'

const snooze = keyframes`
  0% {
    transform: translate(0%,7%) rotate(-5deg) scale(1);
    opacity: 1;
  }
  25% {
    transform: translate(10%,0%) rotate(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(3%,-6%) rotate(5deg) scale(1);
  }
  75% {
    transform: translate(-6%,-11%) rotate(2deg) scale(1.5);
    opacity: 0.3;
  }
  100% {
    transform: translate(10%,-27%) rotate(0deg) scale(2);
    opacity: 0;
  }

`

const Bed = ({ className, fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 123 123"
    version="1.1"
    background="transparent"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className={className}
    css={theme => css`
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
      fill: ${fill || theme.colors.gray};
      overflow: hidden;
      :hover {
        #z1,
        #z2 {
          animation: ${snooze} 2s linear infinite;
        }

        #z2 {
          animation-delay: 1s;
        }
      }

      .z {
        opacity: 0;
        fill: #10a2dd;
      }

      #z1,
      #z2 {
        transform-origin: 25% 50%;
      }
    `}
  >
    <path
      id="bed"
      d="M33.504,84.56c9.28,0 16.768,-7.328 16.768,-16.384c0,-9.056 -7.488,-16.384 -16.768,-16.384c-9.28,0 -16.768,7.328 -16.768,16.384c0,9.056 7.52,16.384 16.768,16.384Zm67.04,-32.768l-44.704,0l0,38.24l-44.672,0l0,-49.152l-11.168,0l0,81.92l11.168,0l0,-16.384l100.544,0l0,16.384l11.168,0l0,-49.152c0,-12.064 -9.984,-21.856 -22.336,-21.856Z"
    />
    <path
      id="z1"
      className="z"
      d="M38.664,49.8l0,1.32l-7.164,0l0,-1.044l4.944,-6.036l-4.86,0l0,-1.32l6.924,0l0,1.044l-4.944,6.036l5.1,0Z"
    />
    <path
      id="z2"
      className="z"
      d="M38.664,49.8l0,1.32l-7.164,0l0,-1.044l4.944,-6.036l-4.86,0l0,-1.32l6.924,0l0,1.044l-4.944,6.036l5.1,0Z"
    />
  </svg>
)

export default Bed
