import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import Name from './Name'

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const AnimatedName = styled(Name)`
  #s {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 1s ease-in forwards;
    animation-delay: 0.5s;
  }

  #i1 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.5s ease-in forwards;
    animation-delay: 1.3s;
  }

  #l {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.5s ease-in forwards;
    animation-delay: 1.6s;
  }

  #i2 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.5s ease-in forwards;
    animation-delay: 1.9s;
  }

  #v {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.5s ease-in forwards;
    animation-delay: 2.2s;
  }

  #e {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.7s ease-in forwards;
    animation-delay: 2.5s;
  }
  #y1 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.5s ease-in forwards;
    animation-delay: 3.2s;
  }

  #y2 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.7s ease-in forwards;
    animation-delay: 3s;
  }
  #p1 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.2s ease-in forwards;
    animation-delay: 4s;
  }

  #p2 {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
    animation: ${dash} 0.2s ease-in forwards;
    animation-delay: 4.3s;
  }
`

export default AnimatedName
