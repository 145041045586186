import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { GoPrimitiveDot } from 'react-icons/go'

const ServiceListItem = ({
  icon = GoPrimitiveDot,
  children,
  dark,
  className,
  main,
}) => {
  const Icon = styled(icon)`
    min-height: 15px;
    min-width: 15px;
    max-width: 15px;
    max-height: 15px;
    margin-right: 10px;
    transform: translateY(4px);
    fill: ${({ theme }) => (dark ? theme.colors.gray : theme.colors.white)};
  `

  if (main) {
    return (
      <li
        css={theme => css`
          list-style: none;
          display: flex;
          justify-content: center;
          padding: 5px 0;
          font-size: ${theme.fontSize.small};
          text-align: center;
        `}
        className={className}
      >
        <div>{children}</div>
      </li>
    )
  }
  return (
    <li
      css={theme => css`
        list-style: none;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 5px 0;
        font-size: ${theme.fontSize.small};
      `}
      className={className}
    >
      <Icon />
      <div>{children}</div>
    </li>
  )
}

export default ServiceListItem
