import React, { useState } from 'react'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import { injectIntl } from 'gatsby-plugin-intl'
import breakpoints from '../utils/breakpoints'
import { LanguageSelector, Link } from './common'
import MenuButton from './MenuButton'
import Brand from './Brand'

const StyledLink = styled(Link)`
  padding: 0 24px;
  color: ${({ theme }) => theme.colors.white};

  ${breakpoints.mobile} {
    padding: 16px;
  }

  :hover {
    opacity: 0.65;
  }

  &.active {
    opacity: 0.65;
  }

  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`

const Header = ({ intl }) => {
  const [open, setOpen] = useState(false)

  return (
    <header
      css={theme => css`
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 999;
        height: ${theme.constants.headerHeightDesktop}px;
        background-color: rgba(44, 44, 43, 0.98);

        ${breakpoints.mobile} {
          height: ${open ? '100%' : `${theme.constants.headerHeightMobile}px`};
          background-color: ${open
            ? theme.colors.black
            : `rgba(44, 44, 43, 0.98)`};
        }

        transition: ${open
          ? `background 0.36s cubic-bezier(0.32, 0.08, 0.24, 1),
          height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);`
          : `background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
          height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);`};
      `}
    >
      <Global
        styles={css`
          body {
            overflow-y: ${open ? 'hidden' : 'auto'};
          }
        `}
      />
      <div
        css={theme => css`
          display: flex;
          flex-direction: row;
          align-items: center;

          height: 100%;
          max-height: ${theme.constants.headerHeightDesktop}px;

          ${breakpoints.mobile} {
            max-height: ${theme.constants.headerHeightMobile}px;
          }
        `}
      >
        <Link to="/" onClick={() => setOpen(false)}>
          <Brand />
        </Link>
        <nav
          css={css`
            display: flex;
            width: 100%;
          `}
        >
          <div
            css={css`
              flex: 1;
            `}
          />
          <MenuButton
            aria-label="show menu"
            onClick={() => setOpen(!open)}
            open={open}
            css={css`
              margin: 0 24px;

              ${breakpoints.desktop} {
                display: none;
              }
            `}
          />

          <div
            css={theme => css`
              display: flex;

              ${breakpoints.mobile} {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;

                margin: ${theme.constants.headerHeightMobile}px 0;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                visibility: ${open ? 'visible' : 'hidden'};
                opacity: ${open ? '1' : '0'};
                transition: opacity 0.3s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s;
              }
            `}
          >
            <StyledLink to="/about/" activeClassName="active" partiallyActive>
              {intl.formatMessage({ id: 'about' })}
            </StyledLink>
            <StyledLink
              to="/services/"
              activeClassName="active"
              partiallyActive
            >
              {intl.formatMessage({ id: 'services' })}
            </StyledLink>
            <StyledLink to="/contact/" activeClassName="active" partiallyActive>
              {intl.formatMessage({ id: 'contact' })}
            </StyledLink>
            <LanguageSelector />
          </div>
        </nav>
      </div>
    </header>
  )
}

export default injectIntl(Header)
