import React from 'react'
import { css } from '@emotion/core'
import breakpoints from '../../utils/breakpoints'

const Service = ({ title, illustration, children }) => (
  <div
    css={css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background: transparent;
      margin-bottom: 64px;

      ${breakpoints.mobile} {
        flex: 1 1 auto;
        max-width: 360px;
        margin: 24px 8px 64px 8px;
      }

      ${breakpoints.sm} {
        max-width: 300px;
      }

      ${breakpoints.lg} {
        max-width: 30%;
      }
    `}
  >
    <div
      css={css`
        width: 80%;
        margin-bottom: 64px;
        max-width: 400px;
        text-align: center;
      `}
    >
      {illustration}
    </div>

    <div
      css={theme => css`
        text-align: center;
        font-size: ${theme.fontSize.large};
        text-transform: uppercase;
        padding-bottom: 24px;
        color: ${theme.colors.accent};
      `}
    >
      {title}
    </div>
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      `}
    >
      {children}
    </div>
  </div>
)

export default Service
