import React from 'react'
import { css } from '@emotion/core'

const Rectangle = ({ className, fill }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 300 150"
    version="1.1"
    background="transparent"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    className={className}
    css={theme => css`
      fill-rule: nonzero;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
      fill: ${fill || theme.colors.dark};
    `}
  >
    <rect x="0" y="0" width="300" height="150" />
  </svg>
)

export default Rectangle
