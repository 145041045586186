import React from 'react'
import { css } from '@emotion/core'
import breakpoints from '../utils/breakpoints'

import { LogoSingle } from './common'

const Brand = () => {
  return (
    <div
      css={theme => css`
        padding: 0 24px;
        font-size: ${theme.fontSize.xLarge};
        color: ${theme.colors.white};
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;

        :hover {
          opacity: 0.65;
        }

        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        ${breakpoints.mobile} {
          font-size: ${theme.fontSize.xLarge};
        }
      `}
    >
      <LogoSingle
        css={css`
          width: 25px;
          height: 25px;
          margin-right: 10px;
        `}
      />
      Silivey
    </div>
  )
}

export default Brand
