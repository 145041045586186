import React from 'react'
import { css } from '@emotion/core'
import breakpoints from '../../utils/breakpoints'

const Motto = ({ children, ...rest }) => (
  <div
    css={theme => css`
      position: relative;
      font-size: ${theme.fontSize.xxLarge};
      max-width: 768px;
      text-align: center;
      padding: 24px 48px 24px 72px;

      ${breakpoints.mobile} {
        font-size: ${theme.fontSize.xLarge};
        padding: 24px 24px 24px 48px;
      }
    `}
    {...rest}
  >
    <div
      css={css`
        position: absolute;
        font-size: 200px;
        top: -48px;
        left: 0px;
        line-height: 1px;
        user-select: none;

        ${breakpoints.mobile} {
          font-size: 120px;
          top: -24px;
          left: 0px;
        }
      `}
    >
      „
    </div>
    {children}
  </div>
)

export default Motto
