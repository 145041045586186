import React from 'react'
import { css } from '@emotion/core'
import CountUp from './CountUp'

import breakpoints from '../../utils/breakpoints'

const Stat = ({
  illustration,
  count,
  start,
  description,
  appendix = '',
  decimals,
}) => (
  <div
    css={theme => css`
      width: 100%;
      max-width: 280px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: ${theme.margin.xxLarge} ${theme.margin.large};
      margin: ${theme.margin.medium};
      border-radius: ${theme.margin.medium};

      transition: filter 0.2s ease-out, transform 0.2s ease-in-out;

      background-color: ${theme.colors.fullWhite};

      ${breakpoints.mobile} {
        flex: 1 1 auto;
        width: 100%;

        padding: ${theme.margin.xLarge} ${theme.margin.large};
      }

      ${breakpoints.md} {
        margin: ${theme.margin.medium};
        margin-top: 0;

        :nth-of-type(odd) {
          transform: translateY(-${theme.margin.xLarge});
        }
      }
    `}
  >
    <div
      css={theme => css`
        text-align: center;
        font-size: ${theme.fontSize.xxLarge};
        padding-bottom: 24px;
        color: ${theme.colors.accent};
      `}
    >
      <CountUp
        end={count}
        start={start}
        appendix={appendix}
        decimals={decimals}
      />
      <div
        css={theme => css`
          text-align: center;
          margin-top: ${theme.margin.small};
          font-size: ${theme.fontSize.small};
          color: ${theme.colors.gray};
        `}
      >
        {description}
      </div>
    </div>
    <div
      css={theme => css`
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;

        margin-top: ${theme.margin.medium};
      `}
    >
      {illustration}
    </div>
  </div>
)

export default Stat
