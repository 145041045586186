const breakpoints = {
  xs: `@media (min-width: 576px)`,
  sm: `@media (min-width: 768px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 1200px)`,
  mobile: `@media only screen and (max-width: 767px)`,
  desktop: `@media only screen and (min-width: 768px)`,
}

export default breakpoints
