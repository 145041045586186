import React from 'react'
import { css } from '@emotion/core'
import Obfuscate from 'react-obfuscate'

const PhoneLink = ({ data, className }) => (
  <Obfuscate
    tel={data}
    className={className}
    target="_blank"
    css={theme => css`
      text-decoration: none;
      color: ${theme.colors.white};

      :hover {
        opacity: 0.65;
      }

      transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    `}
  />
)

export default PhoneLink
