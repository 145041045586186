import React, { useState, useEffect, useRef } from 'react'
import { Global, css } from '@emotion/core'
import useVisibilitySensor from '@rooks/use-visibility-sensor'
import { useCountUp } from 'react-countup'

const easingFn = (t, b, c, d) => {
  // https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
  // eslint-disable-next-line
  return -c * ((t = t / d - 1) * t * t * t - 1) + b
}

const CountUp = ({
  end,
  className,
  appendix = '',
  start: startInput = 0,
  decimals = 0,
}) => {
  const rootNode = useRef(null)
  const { isVisible } = useVisibilitySensor(rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    scrollDebounce: 10,
    partialVisibility: true,
  })
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const delay = end * 0.01 * 2
  const { countUp, start } = useCountUp({
    start: startInput,
    end,
    delay: 1000,
    duration: delay < 1 ? 1 : delay,
    useEasing: true,
    decimals,
    easingFn,
    onEnd: () => setFinished(true),
  })

  useEffect(() => {
    if (!started && isVisible) {
      start()
      setStarted(true)
    }
  }, [isVisible, start, started])

  return (
    <div ref={rootNode} className={className}>
      <div className="counter">{`${finished ? end : countUp}${appendix}`}</div>
      <noscript>
        <Global
          styles={css`
            .counter {
              display: none;
            }
          `}
        />
      </noscript>
      <noscript>{`${end}${appendix}`}</noscript>
    </div>
  )
}

export default CountUp
